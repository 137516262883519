import cn from "classnames"
import { ArrowRight } from "phosphor-react"
import React from "react"
import { projects } from "../../../config"
import Button from "../../freestanding/button/button"
import Container from "../../freestanding/containers/container"
import Grid from "../../freestanding/containers/grid"
import ContentText from "../../freestanding/content/content-text"
import Molecule from "../../freestanding/molecule/molecule"
import MoleculeInteraction from "../../freestanding/molecule/molecule-interaction"
import {
  pb16,
  pb48,
  pb64,
  pb8,
  pt24,
  pt32,
} from "../../freestanding/utils/padding.module.css"
import { features, featuresContent } from "./projects.module.css"

export interface PropTypes {
  id: string
  background?: "dark" | "grey" | "light-grey" | "themed"
  slant?: boolean
  title: React.ReactElement
  description?: React.ReactElement
  buttons?: React.ReactNode
}

const Projects = ({
  id,
  background,
  slant,
  title,
  description,
  buttons,
}: PropTypes) => {
  return (
    <div
      id={id}
      className={cn(
        features,
        {
          ["background-is-grey"]: background === "grey",
          ["background-is-dark"]: background === "dark",
          ["background-is-light-grey"]: background === "light-grey",
          ["background-is-themed"]: background === "themed",
        },
        { ["is-slanted-top-medium"]: slant === true },
      )}
    >
      <Container fluid={true} alignItems={"start"}>
        <Grid lg={4} md={12} sm={12} xs={12} className={cn(pb64)}>
          <ContentText>
            <Molecule>
              <h1 className={cn("font-h1")}>{title}</h1>
              {description && (
                <p className={cn("font-p-large", pt32)}>{description}</p>
              )}
            </Molecule>
            <MoleculeInteraction className={cn(pt24)}>
              {buttons}
            </MoleculeInteraction>
          </ContentText>
        </Grid>
        <Grid lg={6} md={12} sm={12} xs={12}>
          <Container alignItems={"start"} justify={"start"}>
            {projects.map((project, index) => (
              <Grid
                lg={6}
                md={6}
                sm={6}
                xs={12}
                className={cn(featuresContent, `theme-${project.id}`)}
                key={index}
              >
                <Container
                  key={index}
                  className={cn(pb48, featuresContent)}
                  flexContainer={"row"}
                  alignItems={"start"}
                >
                  <ContentText>
                    <em
                      className={cn(
                        "font-overline is-themed-primary not-italic",
                        pb8,
                      )}
                    >
                      / {project.title}
                    </em>
                    <h2 className={cn("font-h5", pb8)}>
                      {project.descriptiveTitle}
                    </h2>
                    <p className={cn("font-p-small", pb8)}>
                      {project.description}
                    </p>
                    <Button
                      to={project.path}
                      style={"link"}
                      iconRight={
                        <ArrowRight
                          weight="bold"
                          size={16}
                          className="themed-primary"
                        />
                      }
                    >
                      Explore {project.title}
                    </Button>
                  </ContentText>
                </Container>
              </Grid>
            ))}
          </Container>
        </Grid>
      </Container>
    </div>
  )
}

export default Projects
